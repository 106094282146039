import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const Perfomance = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Image>
					<SkImage {...images.find((image) => image.name === data.first_image).image} />
				</Image>
				<Image>
					<SkImage {...images.find((image) => image.name === data.second_image).image} />
				</Image>
				<Image>
					<SkImage {...images.find((image) => image.name === data.third_image).image} />
				</Image>
				<Image>
					<SkImage {...images.find((image) => image.name === data.last_image).image} />
				</Image>
				<Content>
					<Title>{data.title}</Title>
					<Subtitle>{data.subtitle}</Subtitle>
					<Text>{data.text}</Text>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 20px 8px;
	${media.md`
		padding: 30px 24px;
	`}
	${media.xl`
		padding: 40px;
	`}
`;

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 565px;
	border-radius: 16px;
	padding: 0 24px;
	background: ${(p) => p.theme.colors.superlightGray};
	width: 100%;
	${media.md`
		height: 620px
        border-radius: 24px;
        padding: 0 100px;
	`}
	${media.xl`
		height: 800px;
        border-radius: 40px;
        padding: 0;
	`}
    > div:nth-child(1) {
		top: 16px;
		left: 24px;
		${media.md`
		    top: 40px;
            left: 56px;
        `}
		${media.xl`
            top: 100px;
            left: 100px;
        `}
	}
	> div:nth-child(2) {
		top: 76px;
		right: 24px;
		${media.md`
		    top: 72px;
            right: 96px;
        `}
		${media.xl`
            top: 60px;
            right: 130px;
        `}
	}
	> div:nth-child(3) {
		bottom: 40px;
		right: 32px;
		${media.md`
		    bottom: 60px;
            right: 56px;
        `}
		${media.xl`
            bottom: 60px;
            right: 100px;
        `}
	}
	> div:nth-child(4) {
		bottom: 16px;
		left: 40px;
		${media.md`
		    bottom: 40px;
            left: 96px;
        `}
		${media.xl`
            bottom: 80px;
            left: 195px;
        `}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 12px;
	${media.md`
		gap: 16px;
	`}
	${media.xl`
        width: 580px;
    `}
`;

const Image = styled.div`
	height: 100px;
	position: absolute;
	> img {
		width: auto;
		height: 100%;
	}
	${media.md`
		height: 140px; 
	`}
	${media.xl`
		height: 210px; 
	`}
`;

const Title = styled.p`
	font-weight: 600;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.blue1};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
        margin-bottom: 8px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h6};
		`}
		line-height: 22px;
	`}
`;
