import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { RemixIcon } from '../shared/remix-icon';

export const Summary = ({ data }) => {
	return (
		<Wrapper>
			<Top>
				<Half>
					<Title>{data.challenges.title}</Title>
					<Items>
						{data.challenges.items.map((item, index) => (
							<TextItem key={item}>
								<Number data-color={'red'}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											d="M5.34338 4.34315C8.53241 7.30668 13.4681 7.30668 16.6571 4.34315C13.6936 7.53218 13.6936 12.4678 16.6571 15.6569C13.4681 12.6933 8.53241 12.6933 5.34338 15.6569C8.30691 12.4678 8.30691 7.53218 5.34338 4.34315Z"
											fill="#E82418"
										/>
									</svg>
									{index + 1}
								</Number>
								<Text>{item}</Text>
							</TextItem>
						))}
					</Items>
				</Half>
				<Half>
					<Title>{data.solutions.title}</Title>
					<Items>
						{data.solutions.items.map((item, index) => (
							<TextItem key={item}>
								<Number data-color={'blue'}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M2 9.88542L4.95313 9.84635C6.09375 10.5039 7.11458 11.3555 7.99349 12.4323C10.2669 8.66276 12.8789 5.57422 15.75 3H18C13.9857 7.45964 10.7096 12.2344 8.08984 17.2917C6.6888 14.2878 4.72917 11.7591 2 9.88542Z"
											fill="#48A1DB"
										/>
									</svg>
									{index + 1}
								</Number>
								<Text>{item}</Text>
							</TextItem>
						))}
					</Items>
				</Half>
			</Top>
			<Bot>
				<Title
					dangerouslySetInnerHTML={{
						__html: highlightString(data.summary.title),
					}}
				/>
				<Items>
					{data.summary.items.map((item) => (
						<Item key={item.title}>
							<IconWrapper>
								<RemixIcon className={item.icon} />
							</IconWrapper>
							<ItemContent>
								<ItemTitle>{item.title}</ItemTitle>
								<Description>{item.description}</Description>
							</ItemContent>
						</Item>
					))}
				</Items>
			</Bot>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 60px 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 80px;
        position: initial;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    `}
`;

const Bot = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.xl`
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    `}
`;

const Half = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
    `}
	${media.xl`
        gap: 24px;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
        margin-bottom: 8px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Number = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 6px;
	&[data-color='red'] {
		color: ${(p) => p.theme.colors.red};
	}
	&[data-color='blue'] {
		color: ${(p) => p.theme.colors.blue1};
	}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	width: 100%;
`;

const IconWrapper = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	color: ${(p) => p.theme.colors.red};
	min-width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
    `}
	color: ${(p) => p.theme.colors.gray6};
`;

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const TextItem = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	${media.md`
        gap: 8px;
    `}
`;

const Description = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray6};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
	color: ${(p) => p.theme.colors.headerText};
`;
