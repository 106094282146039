import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const SoldAbout = ({ data, images }) => {
	return (
		<Container>
			<Number>01.</Number>
			<Wrapper>
				<Title>{data.title}</Title>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</ImageWrapper>
				<Content>
					<Text>{data.description}</Text>
					<Items>
						{data.items.map((item) => (
							<Item key={item.title}>
								<IconWrapper>
									<RemixIcon className={item.icon} />
								</IconWrapper>
								<ItemContent>
									<ItemTitle>{item.title}</ItemTitle>
									<Description>{item.description}</Description>
								</ItemContent>
							</Item>
						))}
					</Items>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	margin: 80px 0 40px 0;
	position: relative;
	${media.md`
		margin: 120px 0;
	`}
	${media.xl`
		margin: 80px 0 160px 0;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 0 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 0 80px;
        height: 465px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
	`}
	${media.xl`
        width: 50%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
	`}
    ${media.xxl`
        width: 60%;
    `}
	@media (min-width: 1750px) {
		text-align: left;
	}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
`;

const Description = styled.p`
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.gray6};
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
                line-height: 24px;
    `}
	color: ${(p) => p.theme.colors.headerText};
`;

const ImageWrapper = styled.div`
	height: 260px;
	margin: 0 auto;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
        height: 400px;
    `}
	${media.xl`
        height: 465px;
        position: absolute;
        top: 0;
        right: 80px;
    `}
    ${media.xxl`
        right: 0;
    `}
`;

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.xl`
        gap: 40px;
        width: 50%;
    `}
	${media.xxl`
        width: 60%;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        flex-direction: row;
        gap: 0;
    `}
	${media.xl`
       flex-direction: column;
       gap: 16px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: row;
	gap: 12px;
	height: 48px;
	width: 100%;
`;

const IconWrapper = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	color: ${(p) => p.theme.colors.red};
	width: 48px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
                line-height: 22px;
    `}
	color: ${(p) => p.theme.colors.gray6};
`;

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	top: 0;
	left: 16px;
	line-height: 16px;
	${media.md`
		left: 24px;
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		left: 40px;
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
    `}
`;
