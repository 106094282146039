import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const SoldReports = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<MainTitle
					dangerouslySetInnerHTML={{
						__html: highlightString(data.title),
					}}
				/>
				<Items>
					{data.items.map((item) => (
						<Item key={item.text}>
							<SkImage {...images.find((image) => image.name === item.image).image} />
							<Text>{item.text}</Text>
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	padding: 20px 8px;
	${media.md`
		padding: 30px 24px;
	`}
	${media.xl`
		padding: 40px;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 16px;
	border-radius: 16px;
	background: ${(p) => p.theme.colors.superlightGray};
	${media.md`
		padding: 24px;
        gap: 24px;
        border-radius: 24px;
	`}
	${media.xl`
		padding: 100px 40px;
        gap: 40px;
        border-radius: 40px;
	`}
	${media.xxl`
		padding: 100px 40px;
		margin: 0 auto;
		max-width: 1540px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	margin: 0 auto;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
        width: 400px;
	`}
	${media.xl`
        width: 600px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
		flex-direction: row;
        flex-wrap: wrap;
	`}
	${media.xl`
        flex-wrap: nowrap;
	`}
`;

const Item = styled.div`
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: fit-content;
	background: ${(p) => p.theme.colors.white};
	${media.md`
		width: calc( 50% - 16px );
	`}
	${media.xl`
		width: 33.333%
	`}
`;

const Text = styled.p`
	font-weight: 400;
	padding: 16px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
        padding: 24px;
	`}
`;
