import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const SoldRealEstate = ({ data, images }) => {
	return (
		<Wrapper>
			<Image>
				<SkImage {...images.find((image) => image.name === data.image).image} />
			</Image>
			<Content>
				<Title
					dangerouslySetInnerHTML={{
						__html: highlightString(data.title),
					}}
				/>
				<Logo>
					<SkImage {...images.find((image) => image.name === data.logo).image} />
				</Logo>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin: 0 8px;
	position: relative;
	display: flex;
	height: auto;
	background: ${(p) => p.theme.colors.superlightGray};
	border-radius: 40px;
	overflow: hidden;
	${media.md`
        margin: 0px 24px;
    `}
	${media.xl`
        margin: 0px 40px;
    `}
`;

const Image = styled.div`
	height: 225px;
	margin-top: 215px;
	${media.md`
        height: 555px;
        margin-top: 250px;
    `}
	${media.xl`
        height: 715px;
        margin-top: 170px;
    `}
    ${media.xl`
        height: 715px;
        margin-top: 290px;
    `}
    > img {
		height: 100%;
		max-width: max-content;
	}
`;

const Title = styled.p`
	font-weight: 600;
	text-align: end;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	line-height: 40px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Content = styled.div`
	position: absolute;
	width: 290px;
	right: 24px;
	top: 24px;
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 16px;
	${media.md`
		width: 580px;
        right: 40px;
	    top: 40px;
        gap: 24px;
	`}
	${media.xl`
		width: 745px;
        right: 98px;
	    top: 136px;
        gap: 32px;
	`}
`;

const Logo = styled.div`
	height: 20px;
	> img {
		height: 100%;
		width: auto;
	}
	${media.md`
		height: 32px;
	`}
`;
