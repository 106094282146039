import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '@hooks';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';
import { RemixIcon } from '../shared/remix-icon';

export const SoldScope = ({ data, images }) => {
	const isMobile = useMediaQuery(`(max-width: 767px`);
	const isTablet = useMediaQuery(`(min-width: 768px`);
	const isDesktop = useMediaQuery(`(min-width: 1200px`);
	const [size, setSize] = useState('desktop');
	useEffect(() => {
		isMobile && setSize('mobile');
		isTablet && setSize('tablet');
		isDesktop && setSize('desktop');
	}, [isMobile, isTablet, isDesktop, size]);
	return (
		<Container>
			<Wrapper>
				<Number>03.</Number>
				<MainTitle>{data.main_title}</MainTitle>
				<Diagram>
					<SkImage {...images.find((image) => image.name === `diagram_${size}`).image} />
				</Diagram>
				<Middle>
					<Content>
						<Title
							dangerouslySetInnerHTML={{
								__html: highlightString(data.title),
							}}
						/>
						<SubTitle>{data.subtitle}</SubTitle>
						<Text>{data.text}</Text>
					</Content>
					<Laptop>
						<SkImage {...images.find((image) => image.name === data.image).image} />
					</Laptop>
				</Middle>
				<Questions>
					{data.questions.map((question, index) => (
						<Question key={question.title}>
							<Icon>
								<RemixIcon className={question.icon} />
							</Icon>
							<QuestionTitle>{question.title}</QuestionTitle>
							<List>
								{question.items.map((item) => (
									<Item key={item}>
										<>
											{index === 0 ? (
												<Box></Box>
											) : (
												<Star>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
													>
														<path
															d="M8 0C8.15945 4.35052 11.6495 7.84055 16 8C11.6495 8.15945 8.15945 11.6495 8 16C7.84055 11.6495 4.35052 8.15945 0 8C4.35052 7.84055 7.84055 4.35052 8 0Z"
															fill="#E82418"
														/>
													</svg>
												</Star>
											)}
										</>
										{item}
									</Item>
								))}
							</List>
						</Question>
					))}
				</Questions>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	${media.xl`
		position: relative;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px 20px 16px;
	position: relative;
	gap: 24px;
	${media.md`
		padding: 60px 24px 30px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 80px 80px 40px 80px;
		gap: 60px;
        position: initial;
	`}
	${media.xxl`
		padding: 80px 0 40px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const MainTitle = styled.p`
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	width: 100%;
	text-align: center;
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h1};
				`}
		line-height: 88px;
	`}
`;

const Diagram = styled.div`
	width: 100%;
	height: fit-content;
	> img {
		width: 100%;
		height: auto;
	}
`;

const Middle = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 24px;
	${media.md`
		gap:40px;
	`}
	${media.xl`
		gap:8px;
		flex-direction: row;
		width: 100%;
	`}
`;

const Laptop = styled.div`
	height: fit-content;
	margin: 0 16px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
		margin: 0 auto;
		width: 525px;
	`}
	${media.xl`
		width: 50%;
		margin: 0;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
		gap: 16px;
	`}
	${media.xl`
		width: 50%;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
		margin-bottom: 8px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Questions = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	`}
	${media.xl`
		gap: 105px;
	`}
`;

const Question = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
		gap: 16px;
	`}
`;

const Icon = styled.div`
	color: ${(p) => p.theme.colors.red};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	${media.xl`
		font-size: 30px;
	`}
`;

const QuestionTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		font-weight: 600;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
`;

const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Item = styled.li`
	display: flex;
	flex-direction: row;
	gap: 12px;
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
		top: 80px;
    `}
`;

const Box = styled.div`
	min-width: 12px;
	min-height: 12px;
	max-width: 12px;
	max-height: 12px;
	background: ${(p) => p.theme.colors.red};
	border-radius: 4px;
	margin: 5px;
`;

const Star = styled.div`
	margin: 3px 5px 0 5px;
`;
