import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const SoldLocalMarket = ({ data, images }) => {
	return (
		<Wrapper>
			<SkImage {...images.find((image) => image.name === data.image).image} />
			<Content>
				<Title
					dangerouslySetInnerHTML={{
						__html: highlightString(data.title),
					}}
				/>
				<SubTitle>{data.subtitle}</SubTitle>
				<Text>{data.text}</Text>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 16px;
	gap: 16px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
		padding: 30px 24px;
        gap: 24px;
        > img {
		    width: 520px;
            margin: 0 auto;
	    }
	`}
	${media.xl`
		padding: 40px 80px;
		flex-direction: row-reverse;
        > img {
		    width: 60%;
            margin: 0;
	    }
	`}
	${media.xxl`
		padding: 40px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.xl`
        width: 40%;
        justify-content: center;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	text-align: center;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    line-height: 32px;
	`}
	${media.xl`
        text-align: left;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
        margin-bottom: 8px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	text-align: center;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
        text-align: left;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	text-align: center;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
        text-align: left;
	`}
`;
