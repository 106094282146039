import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const SoldInsight = ({ data, images }) => {
	return (
		<Wrapper>
			<MainTitle
				dangerouslySetInnerHTML={{
					__html: highlightString(data.title),
				}}
			/>
			<Items>
				{data.items.map((item, index) => (
					<Item key={item.title} data-right={(index + 1) % 2 === 0 ? 'true' : 'false'}>
						<SkImage {...images.find((image) => image.name === item.image).image} />
						<Content>
							<Title>{item.title}</Title>
							<SubTitle>{item.subtitle}</SubTitle>
							<Text>{item.text}</Text>
						</Content>
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 16px;
	gap: 20px;
	${media.md`
		padding: 30px 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 40px 80px;
	`}
	${media.xxl`
		padding: 40px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.xl`
        gap: 16px;
        justify-content: center;
        width: 50%;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
        line-height: 32px;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
        line-height: 40px;
        margin-bottom: 8px;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 40px;
    `}
	${media.xl`
        gap: 60px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	> img {
		border-radius: 8px;
	}
	${media.md`
        > img {
            border-radius: 16px;
        }
        gap: 24px;
    `}
	${media.xl`
        flex-direction: row;
        gap: 40px;
        > img {
            width: 50%;
            height: auto;
        }
    `}
    &[data-right='true'] {
		${media.xl`
            flex-direction: row-reverse;
	    `}
	}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    line-height: 32px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;
