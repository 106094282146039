import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';
export const Achievements = ({ data, images }) => {
	return (
		<Container>
			<Number>08.</Number>
			<Wrapper>
				<MainTitle>{data.main_title}</MainTitle>
				<Items>
					{data.items.map((item, index) => (
						<Item key={item.title} data-left={(index + 1) % 2 !== 0 ? 'true' : 'false'}>
							<Image>
								<SkImage {...images.find((image) => image.name === item.image).image} />
							</Image>
							<Content>
								<Title
									dangerouslySetInnerHTML={{
										__html: highlightString(item.title),
									}}
								/>
								<Text>{item.text}</Text>
							</Content>
						</Item>
					))}
				</Items>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
	margin: 40px 8px;
	border-radius: 16px;
	background: ${(p) => p.theme.colors.superlightGray};
	${media.md`
        margin: 60px 24px;
        border-radius: 24px;
    `}
	${media.md`
        margin: 80px 40px;
        border-radius: 40px;
    `}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 40px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 100px 40px;
        position: initial;
	`}
	${media.xxl`
		margin: 0 auto;
		max-width: 1540px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	width: 80%;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	text-align: center;
	margin: 0 auto;
	${media.md`
        width: 100%;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 40px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
        flex-direction: row;
        align-items: center;
        
    `}
	${media.xl`
        gap: 40px;
    `}
    &[data-left='true'] {
		${media.md`
            flex-direction: row-reverse;
        `}
	}
`;

const Content = styled.div`
	display: flex;
	gap: 12px;
	flex-direction: column;
	${media.md`
        gap: 16px;
    `}
	${media.xl`
        gap: 24px;
    `}
`;

const Image = styled.div`
	border-radius: 8px;
	overflow: hidden;
	${media.md`
        min-width: 50%;
        > img {
            width: 100%;
            height: auto;
        }
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
        margin-bottom: 8px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Text = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.white};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	top: 24px;
	left: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
        top: 45px;
        left: 24px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
        top: 108px;
    `}
`;
