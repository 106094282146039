import React from 'react';
import styled, { css } from 'styled-components';

import { highlightString } from '@utils';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const SoldTechnologies = ({ data, images }) => {
	return (
		<Wrapper>
			<Header>
				<MainTitle
					dangerouslySetInnerHTML={{
						__html: highlightString(data.title),
					}}
				/>
				<MainSubTitle>{data.subtitle}</MainSubTitle>
			</Header>
			<Blue>
				<BlueImage>
					<SkImage {...images.find((image) => image.name === data.blue.image).image} />
				</BlueImage>
				<BlueContent>
					<BlueTitle>{data.blue.title}</BlueTitle>
					<BlueSubTitle>{data.blue.subtitle}</BlueSubTitle>
					<BlueText>{data.blue.text}</BlueText>
				</BlueContent>
			</Blue>
			<Items>
				{data.items.map((item, index) => (
					<Item key={item.title} data-right={(index + 1) % 2 === 0 ? 'true' : 'false'}>
						<SkImage {...images.find((image) => image.name === item.image).image} />
						<Content>
							<Title>{item.title}</Title>
							<SubTitle>{item.subtitle}</SubTitle>
							{item.list && (
								<ListWrapper>
									<ListTitle>{item.list.text}</ListTitle>
									<List>
										{item.list.items.map((li) => (
											<ListItem key={item}>
												<Star>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="16"
														height="16"
														viewBox="0 0 16 16"
														fill="none"
													>
														<path
															d="M8 0C8.15945 4.35052 11.6495 7.84055 16 8C11.6495 8.15945 8.15945 11.6495 8 16C7.84055 11.6495 4.35052 8.15945 0 8C4.35052 7.84055 7.84055 4.35052 8 0Z"
															fill="#E82418"
														/>
													</svg>
												</Star>
												{li}
											</ListItem>
										))}
									</List>
								</ListWrapper>
							)}
							<Text>{item.text}</Text>
						</Content>
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 16px;
	gap: 24px;
	${media.md`
		padding: 30px 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 40px 80px;
	`}
	${media.xxl`
		padding: 40px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
        width: 600px;
        margin: 0 auto;
    `}
	${media.xl`
        gap: 24px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	text-align: left;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	width: 230px;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${media.md`
        text-align: center;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
        line-height: 32px;
        width: 275px;
        margin: 0 auto;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t6};
				`}
        line-height: 40px;
        width: 320px;
    `}
`;

const MainSubTitle = styled.div`
	font-weight: 400;
	text-align: left;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	letter-spacing: 0.01em;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
       text-align: center;
    `}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
        line-height: 24px;
    `}
`;

const Blue = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	background: ${(p) => p.theme.colors.blue};
	border-radius: 8px;
	padding: 16px;
	${media.md`
       flex-direction: row;
       gap: 40px;
       border-radius: 16px;
       padding: 40px;
    `}
	${media.xl`
        gap: 93px;
        padding: 80px 97px;
    `}
    ${media.xxl`
        gap: 245px;
        padding: 80px 132px;
    `}
`;

const BlueTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.white};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    line-height: 32px;
        margin-bottom: 8px;
	`}
`;

const BlueSubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.white};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
	`}
`;

const BlueText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.white};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;

const BlueContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.xl`
        justify-content: center;
}
    `}
`;

const BlueImage = styled.div`
	> img {
		min-width: 130px;
		width: 130px;
		height: auto;
		margin: 0 auto;
	}
	${media.md`
        display: flex;
        align-items: center;
        > img {
            margin: 0;
            min-width: 100px;
            width: 100px;
        }
	`}
	${media.xl`
        > img {
            min-width: 170px;
            width: 170px;
        }
	`}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        gap: 40px;
    `}
	${media.xl`
        gap: 60px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	> img {
		border-radius: 8px;
		width: 100%;
	}
	${media.md`
        > img {
            border-radius: 16px;
        }
        gap: 24px;
    `}
	${media.xl`
        flex-direction: row-reverse;
        gap: 40px;
        > img {
            width: 480px;
            height: auto;
        }
    `}
    &[data-right='true'] {
		${media.xl`
            flex-direction: row;
	    `}
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.xl`
        gap: 16px;
        justify-content: center;
        width: 100%;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h4};
				`}
	    line-height: 32px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;

const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Star = styled.div`
	margin: 2px 5px 0 5px;
`;

const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ListItem = styled.li`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	display: flex;
	flex-direction: row;
	gap: 8px;
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;

const ListTitle = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;
