import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const SoldProjectData = ({ data, images }) => {
	return (
		<Container>
			<Wrapper>
				<Number>02.</Number>
				<Title>{data.title}</Title>
				<Content>
					<Red>
						{data.items.map((item) => (
							<Item key={item.value}>
								<Value>{item.value}</Value>
								<Label>{item.label}</Label>
							</Item>
						))}
					</Red>
					<Image>
						<SkImage {...images.find((image) => image.name === data.image).image} />
					</Image>
				</Content>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	${media.xl`
		position: relative;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 16px 40px 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 120px 24px 0 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 160px 40px 0 40px;
        gap: 60px;
		position: initial;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	${media.xl`
        display: grid;
        grid-template-columns: 1fr 2fr;
	`}
	${media.xxl`
        margin: 0 auto;
	`}
`;

const Image = styled.div`
	border-radius: 16px;
	overflow: hidden;
	${media.md`
		border-radius: 24px;
	`}
	${media.xl`
		border-radius: 40px;
        display: flex;
        justify-content: center;
		align-items: center;
		height: 802px;
        > img {
            max-height: 100%;
			height: 100%;
            max-width: max-content;
        }
	`}
`;

const Label = styled.p`
	color: ${(p) => p.theme.colors.white};
	font-weight: 400;
	opacity: 60%;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height:16px;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
		line-height: 22px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
		line-height: 24px;
	`}
`;

const Value = styled.p`
	color: ${(p) => p.theme.colors.white};
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height:28px;
	letter-spacing: 0.01em;
	${media.md`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h2};
				`}
		line-height: 72px;
	`}
`;

const Red = styled.div`
	background: ${(p) => p.theme.colors.red};
	border-radius: 16px;
	padding: 24px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	${media.md`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
		padding: 40px 105px;
        gap: 0px;
        border-radius: 24px;
	`}
	${media.xl`
        flex-direction: column;
		padding: 145px 0;
        gap: 32px;
        border-radius: 40px;
	`}
`;

const Title = styled.p`
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 600;
	width: 65%;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	text-align: center;
	margin: 0 auto;
	line-height: 32px;
	letter-spacing: 0.01em;
	${media.md`
		width: 100%;
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
		line-height: 48px;
	`}
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h1};
				`}
		line-height: 88px;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	${media.md`
        gap: 8px;
    `}
	${media.xl`
        min-width:  max-content;
    `}
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
		top: 175px;
    `}
`;
