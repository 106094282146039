import React from 'react';
import styled, { css } from 'styled-components';

import { media, transition } from '../../styles/mixins';

export const SoldMetrics = ({ data }) => {
	return (
		<Wrapper>
			<Items>
				{data.items.map((item) => (
					<Item key={item.points[0].label}>
						{item.points.map((point) => (
							<Point key={point.value}>
								<Value>{point.value}</Value>
								<Label>{point.label}</Label>
							</Point>
						))}
					</Item>
				))}
			</Items>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	padding: 40px 16px;
	${media.md`
		padding: 60px 24px;
	`}
	${media.xl`
		padding: 80px;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Items = styled.div`
	display: grid;
	background: ${(p) => p.theme.colors.superlightGray};
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 1px;
	${media.xl`
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
    `}
`;

const Item = styled.div`
	background: ${(p) => p.theme.colors.white};
	${transition('all')};
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        padding: 24px;
        gap: 24px;
    `}
	&:hover {
		background: ${(p) => p.theme.colors.superlightGray};
	}
`;

const Point = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	${media.md`
        gap: 8px;
    `}
`;

const Label = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	color: ${(p) => p.theme.colors.headerText};
	line-height: 16px;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t2};
		`}
		line-height: 20px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Value = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	color: ${(p) => p.theme.colors.red};
	line-height: 28px;
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t6};
		`}
		line-height: 40px;
	`}
`;
