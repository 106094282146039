import React from 'react';
import styled, { css } from 'styled-components';

import { highlightString } from '@utils';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const Salesforce = ({ data, images }) => {
	return (
		<Container>
			<Number>06.</Number>
			<Wrapper>
				<Top>
					<Cloud>
						<SkImage {...images.find((image) => image.name === data.cloud).image} />
					</Cloud>
					<Content>
						<MainTitle>{data.main_title}</MainTitle>
						<Subtitle>{data.main_subtitle}</Subtitle>
						<Text>{data.main_text}</Text>
					</Content>
				</Top>
				<Bot>
					<Image>
						<SkImage {...images.find((image) => image.name === data.image).image} />
						<Name>{data.name}</Name>
						<Surname>{data.surname}</Surname>
					</Image>
					<Content>
						<Title
							dangerouslySetInnerHTML={{
								__html: highlightString(data.title),
							}}
						/>
						<Subtitle>{data.subtitle}</Subtitle>
						<Text>{data.text}</Text>
					</Content>
				</Bot>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	position: relative;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 80px 24px;
	gap: 24px;
	${media.md`
		padding: 120px 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 160px 80px;
        position: initial;
	`}
	${media.xxl`
		padding: 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
    `}
	${media.xl`
        gap: 40px;
        flex-direction: row;
        align-items: center;
    `}
`;

const Bot = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
    `}
	${media.md`
        gap: 40px;
    `}
    ${media.xl`
        width: 65%;
        margin: 0 auto;
    `}
    p {
		text-align: center;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	color: ${(p) => p.theme.colors.headerText};
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	${media.xl`
        margin-bottom: 8px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const Cloud = styled.div`
	width: 100%;
	padding: 0 40px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        margin: 0 auto;
        width: 400px;
        padding: 35px 45px;
    `}
	${media.xl`
        margin: 0;
        min-width: 480px;
        padding: 40px 55px;
    `}
`;

const Image = styled.div`
	width: 100%;
	position: relative;
	> img {
		width: 100%;
		height: auto;
		border-radius: 8px;
		z-index: 1;
		position: inherit;
	}
	p {
		display: none;
	}
	${media.md`
        > img {
            border-radius: 16px;
        }
    `}
	${media.xl`
        p {
            display: block;
        }
    `}
`;

const Name = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h1};
	`}
	line-height: 88px;
	color: ${(p) => p.theme.colors.superlightGray};
	letter-spacing: 0.01em;
	position: absolute;
	top: 75px;
	left: -140px;
`;

const Surname = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h1};
	`}
	line-height: 88px;
	color: ${(p) => p.theme.colors.superlightGray};
	letter-spacing: 0.01em;
	position: absolute;
	bottom: 30px;
	right: -180px;
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	top: 155px;
	left: 16px;
	line-height: 16px;
	${media.md`
		left: 24px;
        top: 240px;
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		left: 40px;
		height: 56px;
		width: 56px;
        top: 322px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
    `}
`;
