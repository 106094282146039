import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { RemixIcon } from '../shared/remix-icon';

export const Resume = ({ data }) => {
	return (
		<Container>
			<Wrapper>
				{data.items.map((item) => (
					<Item key={item.title}>
						<IconWrapper>
							<RemixIcon className={item.icon} />
						</IconWrapper>
						<Title>{item.title}</Title>
						<Text>{item.description}</Text>
					</Item>
				))}
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	margin: 40px 8px 80px 8px;
	border-radius: 16px;
	${media.md`
        margin: 30px 24px 120px 24px;
        border-radius: 24px;
    `}
	${media.xl`
        margin: 40px 40px 160px 40px;
        border-radius: 40px;
    `}
    background: ${(p) => p.theme.colors.superlightGray};
`;

const Wrapper = styled.div`
	padding: 24px 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
       padding: 40px 24px;
       display: grid;
       grid-template-columns: repeat(3, 1fr);
       gap: 24px;
    `}
	${media.xl`
        margin: 40px 40px 160px 40px;
        gap: 40px;
    `}
    ${media.xl`
        padding: 100px 40px;
        margin: 0 auto;
        max-width: 1540px;
    `}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Title = styled.div`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
        margin-bottom: 8px;
	`}
	${media.xl`
        width: 310px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Text = styled.div`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const IconWrapper = styled.div`
	width: 48px;
	height: 48px;
	background: ${(p) => p.theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(p) => p.theme.colors.red};
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t5};
	`}
	${media.md`
        margin-bottom: 8px;
    `}
	${media.xl`
        width: 64px;
        height: 64px;
        font-size: 26px;
    `}
    border-radius: 8px;
`;
