import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { useMediaQuery } from '@hooks';
import { highlightString } from '@utils';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const Statistics = ({ data, images }) => {
	const isMobile = useMediaQuery(`(max-width: 767px`);
	const isTablet = useMediaQuery(`(min-width: 768px`);
	const isDesktop = useMediaQuery(`(min-width: 1200px`);
	const [size, setSize] = useState('desktop');
	useEffect(() => {
		isMobile && setSize('mobile');
		isTablet && setSize('tablet');
		isDesktop && setSize('desktop');
	}, [isMobile, isTablet, isDesktop, size]);
	return (
		<Container>
			<Wrapper>
				<Number>05.</Number>
				<MainTitle
					dangerouslySetInnerHTML={{
						__html: highlightString(data.main_title),
					}}
				/>
				<Top>
					<Content>
						<Title>
							{data.title}
							<Icon>
								<SkImage {...images.find((image) => image.name === data.icon).image} />
							</Icon>
						</Title>
						<Subtitle>{data.subtitle}</Subtitle>
						<Text>{data.text}</Text>
					</Content>
					<Image>
						<SkImage {...images.find((image) => image.name === data.side_image).image} />
					</Image>
				</Top>
				<Bot>
					<GrayTitle>{data.gray_title}</GrayTitle>
					<Graph>
						<SkImage {...images.find((image) => image.name === `graph_${size}`).image} />
					</Graph>
				</Bot>
			</Wrapper>
		</Container>
	);
};

const Container = styled.div`
	background: ${(p) => p.theme.colors.superlightGray};
	border-radius: 24px;
	position: relative;
	${media.xl`
		border-radius: 40px;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 80px 16px;
	${media.md`
		padding: 120px 24px;
        gap: 40px;
	`}
	${media.xl`
		padding: 160px 80px;
        gap: 60px;
	`}
	${media.xxl`
		padding: 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	text-align: center;
	margin: 0 auto;
	width: 243px;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
		width: 417px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
		width: 975px;
	`}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 16px;
	${media.md`
		gap: 24px;
	`}
	${media.xl`
		flex-direction: row;
		gap: 40px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.xl`
		gap: 16px;
	`}
`;

const Image = styled.div`
	width: 100%;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
		width: 440px;
		margin: 0 auto;
	`}
	${media.xl`
		min-width: 580px;
		margin: 0;
	`}
`;

const Title = styled.div`
	display: flex;
	flex-direction: row;
	font-weight: 600;
	align-items: center;
	gap: 8px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		gap: 16px;
		margin-bottom: 8px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
	    line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	letter-spacing: 0.01em;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
	    line-height: 22px;
	`}
`;

const Icon = styled.div`
	width: 24px;
	height: 24px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.xl`
		width: 32px;
		height: 32px;
	`}
`;

const Bot = styled.div`
	display: flex;
	flex-direction: column;
`;

const GrayTitle = styled.div`
	font-weight: 600;
	text-align: center;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t6};
	`}
	line-height: 40px;
	color: ${(p) => p.theme.colors.lightGray};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
	${media.xl`
		text-align: end;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h1};
		`}
		line-height: 88px;
	`}
`;

const Graph = styled.div`
	width: 100%;
	> img {
		width: 100%;
		height: auto;
	}
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.white};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
		left: 24px;
		top: 124px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
		top: 170px;
    `}
`;
