import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { SkImage } from '../shared/image';

export const Ranking = ({ data, images }) => {
	return (
		<Wrapper>
			<Top>
				<MainContent>
					<MainTitle>{data.main_title}</MainTitle>
					<MainSubtitle>{data.main_subtitle}</MainSubtitle>
				</MainContent>
				<Items>
					{data.items.map((item) => (
						<Item key={item.title}>
							<ItemImage>
								<SkImage {...images.find((image) => image.name === item.image).image} />
							</ItemImage>
							<ItemTitle>{item.title}</ItemTitle>
							<ItemText>{item.text}</ItemText>
						</Item>
					))}
				</Items>
			</Top>
			<Bottom>
				<ImageWrapper>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</ImageWrapper>
				<Content>
					<Label>{data.label}</Label>
					<Title>{data.title}</Title>
					<Subtitle>{data.subtitle}</Subtitle>
					<Text>{data.text}</Text>
				</Content>
			</Bottom>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 16px 80px 16px;
	gap: 40px;
	position: relative;
	${media.md`
		padding: 30px 24px 120px 24px;
        gap: 60px;
	`}
	${media.xl`
        gap: 65px;
		padding: 40px 80px 160px 80px;
        position: initial;
	`}
	${media.xxl`
		padding: 40px 0 160px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const Top = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
        padding: 40px 0;
    `}
	${media.xl`
        flex-direction: row;
        gap: 40px;
        align-items: center;
    `}
`;

const MainContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
        margin-bottom: 40px;
    `}
	${media.xl`
        margin-bottom: 0;
        gap: 24px;
        min-width: 545px;
        width: 545px;
    `}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const MainSubtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
	    line-height: 24px;
	`}
`;

const Item = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	${media.md`
        gap: 8px;
    `}
`;

const Items = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${media.md`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 24px;
        > div {
            width: calc( 50% - 12px );
        }
        margin-bottom: -40px;
    `}
	> div:nth-child(1) {
		${media.md`
		    margin-top: -40px;
        `}
	}
	> div:nth-child(3) {
		${media.md`
		    margin-top: -40px;
        `}
	}
`;

const ItemImage = styled.div`
	width: 40px;
	margin-bottom: 4px;
	${media.md`
        width: 56px;
        margin-bottom: 8px;
    `}
`;

const ItemTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t4};
	`}
	line-height: 24px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h5};
				`}
	    line-height: 28px;
	`}
`;

const ItemText = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h6};
				`}
	    line-height: 22px;
	`}
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 40px;
    `}
	${media.xl`
        flex-direction: row;
    `}
`;

const ImageWrapper = styled.div`
	width: 100%;
	height: 280px;
	overflow: hidden;
	display: flex;
	align-items: center;
	border-radius: 8px;
	${media.md`
        height: 400px;
        border-radius: 16px;
    `}
	${media.xl`
        height: 480px;
        min-width: 480px;
    `}
	> img {
		width: 100%;
		height: auto;
	}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const Label = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	margin-bottom: -12px;
	line-height: 20px;
	color: ${(p) => p.theme.colors.red};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
        margin-bottom: -8px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
        margin-bottom: -4px;
	`}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
	    line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	letter-spacing: 0.01em;
	${media.xl`
    ${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
	    line-height: 22px;
	`}
`;
