import React from 'react';
import { navigate } from 'gatsby';

import { PopupProvider } from '@context/popup-context';
import { TemplateProps } from '@templates/types';

import { GetInTouch } from '../../components/get-in-touch';
import { ReviewSection } from '../../components/review';
import { Popup } from '../../components/popup';
import { Seo } from '../../components/shared/seo';
import { SoldAbout } from '../../components/sold/about';
import { Achievements } from '../../components/sold/achievements';
import { Bot } from '../../components/sold/bot';
import { SoldHero } from '../../components/sold/hero';
import { SoldInsight } from '../../components/sold/insight';
import { SoldLocalMarket } from '../../components/sold/local-market';
import { SoldMetrics } from '../../components/sold/metrics';
import { Perfomance } from '../../components/sold/perfomance';
import { SoldPortal } from '../../components/sold/portal';
import { SoldProjectData } from '../../components/sold/project-data';
import { Ranking } from '../../components/sold/ranking';
import { SoldRealEstate } from '../../components/sold/real-estate';
import { SoldReports } from '../../components/sold/reports';
import { Resume } from '../../components/sold/resume';
import { Salesforce } from '../../components/sold/salesforce';
import { SoldScope } from '../../components/sold/scope';
import { Statistics } from '../../components/sold/statistics';
import { Summary } from '../../components/sold/summary';
import { SoldTechnologies } from '../../components/sold/technologies';
import MainLayout from '../../layouts/main-layout';

export const SoldComTemplate = (props: TemplateProps) => {
	const { page, menu, header, footer, cookieBanner, theme, ctaPopup, seo } = props.pageContext;
	const attributes = page.attributes;

	if (!page) {
		return navigate('/404');
	}
	const { GlobalMeta } = seo.data.attributes;

	return (
		<MainLayout
			menu={menu}
			header={header}
			footer={footer}
			cookieBanner={cookieBanner}
			overflow="hidden"
			theme={theme}
			popup={ctaPopup}
		>
			<Seo meta={page.attributes.meta} globalMeta={GlobalMeta} url={page.attributes.url} />
			{attributes && (
				<PopupProvider>
					{ctaPopup?.data && (
						<Popup
							form={ctaPopup.data.attributes.form}
							formResponse={ctaPopup.data.attributes.formResponse}
						/>
					)}
					<SoldHero
						data={attributes.content.hero}
						images={attributes.images}
						path={attributes.url}
					/>
					<SoldAbout data={attributes.content.section_1} images={attributes.images} />
					<SoldRealEstate data={attributes.content.section_2} images={attributes.images} />
					<SoldProjectData data={attributes.content.section_3} images={attributes.images} />
					<SoldMetrics data={attributes.content.section_4} />
					<SoldScope data={attributes.content.section_5} images={attributes.images} />
					<SoldReports data={attributes.content.section_6} images={attributes.images} />
					<SoldLocalMarket data={attributes.content.section_7} images={attributes.images} />
					<SoldInsight data={attributes.content.section_8} images={attributes.images} />
					<SoldTechnologies data={attributes.content.section_9} images={attributes.images} />
					<SoldPortal data={attributes.content.section_10} images={attributes.images} />
					<Perfomance data={attributes.content.section_11} images={attributes.images} />
					<Ranking data={attributes.content.section_12} images={attributes.images} />
					<Statistics data={attributes.content.section_13} images={attributes.images} />
					<Salesforce data={attributes.content.section_14} images={attributes.images} />
					<Bot data={attributes.content.section_15} images={attributes.images} />
					<Achievements data={attributes.content.section_16} images={attributes.images} />
					<Summary data={attributes.content.section_17} />
					<Resume data={attributes.content.section_18} />
					{attributes?.sections?.data[0] && <ReviewSection data={attributes.sections.data[0]} />}
					<GetInTouch images={attributes.images} />
				</PopupProvider>
			)}
		</MainLayout>
	);
};
