import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const SoldPortal = ({ data, images }) => {
	return (
		<Layout>
			<Wrapper>
				<Number>04.</Number>
				<MainTitle
					dangerouslySetInnerHTML={{
						__html: highlightString(data.title),
					}}
				/>
				<Container>
					<Content>
						<Title>{data.title}</Title>
						<SubTitle>{data.subtitle}</SubTitle>
						<Text>{data.text}</Text>
					</Content>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Container>
			</Wrapper>
		</Layout>
	);
};

const Layout = styled.div`
	margin: 40px 0;
	${media.md`
		margin: 60px 0;
	`}
	${media.xl`
        margin: 80px 0;
		position: relative;
	`}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 0 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 0 80px;
        position: initial;
	`}
	${media.xxl`
		padding: 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const MainTitle = styled.div`
	font-weight: 600;
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	text-align: center;
	margin: 0 auto;
	width: 65%;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
        width: 560px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
        width: 1105px;
	`}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column-reverse;
	gap: 16px;
	width: 100%;
	height: auto;
	${media.md`
        gap: 24px;
        > img {
            width: 445px;
            margin: 0 auto;
        }
    `}
	${media.xl`
        gap: 40px;
        flex-direction: row;
        > img {
            width: 580px;
            margin: 0;
        }
    `}
    ${media.xxl`
        > img {
            width: 785px;
        }
    `}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
    `}
	${media.xl`
        justify-content: center;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.h3};
				`}
	    line-height: 48px;
        margin-bottom: 8px;
	`}
`;

const SubTitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t3};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t4};
				`}
	    line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
        ${(p) => css`
					font-size: ${p.theme.typography.sizes.t3};
				`}
	    line-height: 22px;
	`}
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
		top: 0;
    `}
`;
