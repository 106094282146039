import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '../../styles/mixins';
import { highlightString } from '../../utils';
import { SkImage } from '../shared/image';

export const Bot = ({ data, images }) => {
	return (
		<Wrapper>
			<Number>04.</Number>
			<MainTitle>{data.main_title}</MainTitle>
			<Content>
				<Image>
					<SkImage {...images.find((image) => image.name === data.image).image} />
				</Image>
				<TextWrapper>
					<Title
						dangerouslySetInnerHTML={{
							__html: highlightString(data.title),
						}}
					/>
					<Subtitle>{data.subtitle}</Subtitle>
					<Text>{data.text}</Text>
				</TextWrapper>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px 16px;
	gap: 24px;
	position: relative;
	${media.md`
		padding: 60px 24px;
        gap: 40px;
	`}
	${media.xl`
        gap: 60px;
		padding: 80px;
        position: initial;
	`}
	${media.xxl`
		padding: 80px 0;
		margin: 0 auto;
		max-width: 1460px;
	`}
`;

const MainTitle = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h4};
	`}
	line-height: 32px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	text-align: center;
	margin: 0 auto;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h2};
		`}
		line-height: 72px;
	`}
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	${media.md`
        gap: 24px;
    `}
	${media.xl`
        gap: 40px;
        flex-direction: row;
        align-items: center;
    `}
`;

const Image = styled.div`
	width: 100%;
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 8px;
	> img {
		width: 100%;
		height: auto;
	}
	${media.md`
        height: 400px;
        border-radius: 16px;
    `}
	${media.xl`
        height: auto;
        min-width: 50%;
    `}
`;

const Title = styled.p`
	font-weight: 600;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h5};
	`}
	> span {
		color: ${(p) => p.theme.colors.red};
	}
	line-height: 28px;
	color: ${(p) => p.theme.colors.headerText};
	letter-spacing: 0.01em;
	${media.md`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h4};
		`}
		line-height: 32px;
        margin-bottom: 8px;
	`}
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.h3};
		`}
		line-height: 48px;
	`}
`;

const Subtitle = styled.p`
	font-weight: 500;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.h6};
	`}
	line-height: 22px;
	color: ${(p) => p.theme.colors.headerText};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t4};
		`}
		line-height: 24px;
	`}
`;

const Text = styled.p`
	font-weight: 400;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t2};
	`}
	line-height: 20px;
	color: ${(p) => p.theme.colors.gray};
	${media.xl`
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
	`}
`;

const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
	${media.md`
        gap: 16px;
        > p {
            text-align: center;
        }
    `}
	${media.xl`
        > p {
            text-align: left;
        }
    `}
`;

const Number = styled.div`
	position: absolute;
	background: ${(p) => p.theme.colors.superlightGray};
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${(p) => p.theme.colors.headerText};
	font-weight: 400;
	border-radius: 48px;
	${(p) => css`
		font-size: ${p.theme.typography.sizes.t1};
	`}
	line-height: 16px;
	${media.md`
		height: 40px;
		width: 40px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t3};
		`}
		line-height: 22px;
    `}
	${media.xl`
		height: 56px;
		width: 56px;
		${(p) => css`
			font-size: ${p.theme.typography.sizes.t5};
		`}
		line-height: 28px;
		left: 40px;
    `}
`;
